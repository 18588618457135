const categories = {
  product: {
    title: 'Product',
    topics: [
      {
        title: 'Get Started',
        articles: [
          {
            title: 'What is rapStudy?',
            articleId: 'what-is-rapstudy',
          },
          {
            title: 'Browser support',
            articleId: 'browser-support',
          },
          {
            title: 'Explore our content offering',
            articleId: 'explore-our-content-offering',
          },
        ],
      },
      {
        title: 'Our Features',
        articles: [
          {
            title: 'Karaoke',
            articleId: 'karaoke',
          },
          {
            title: 'Breakdown',
            articleId: 'breakdown',
          },
          {
            title: 'Blanks',
            articleId: 'blanks',
          },
          {
            title: 'Review',
            articleId: 'review',
          },
        ],
      },
      {
        title: 'Organizing and sharing',
        articles: [
          {
            title: 'Your study sets',
            articleId: 'your-study-sets',
          },
          {
            title: 'Playlists',
            articleId: 'playlists',
          },
          {
            title: 'Sharing to Google Classroom',
            articleId: 'sharing-to-google-classroom',
          },
        ],
      },
      {
        title: 'Classes (teachers)',
        articles: [
          {
            title: 'Creating a class',
            articleId: 'creating-a-class',
          },
          {
            title: 'Sharing content',
            articleId: 'sharing-content',
          },
          {
            title: 'Creating an assignment',
            articleId: 'creating-an-assignment',
          },
          {
            title: 'Viewing student submissions',
            articleId: 'viewing-student-submissions',
          },
        ],
      },
      {
        title: 'Classes (students)',
        articles: [
          {
            title: 'Joining a class',
            articleId: 'joining-a-class',
          },

          {
            title: 'Doing an assignment',
            articleId: 'doing-an-assignment',
          },
        ],
      },
    ],
  },
  account: {
    title: 'Account',
    topics: [
      {
        title: 'Logging in',
        articles: [
          {
            title: 'Creating an account via Google',
            articleId: 'create-account-google',
          },
          {
            title: 'Creating an account via email and password',
            articleId: 'create-account-email',
          },
          {
            title: 'Onboarding and verification',
            articleId: 'onboarding-and-verification',
          },
        ],
      },
      {
        title: 'Troubleshooting',
        articles: [
          {
            title: 'Forgot your password?',
            articleId: 'forgot-password',
          },
          {
            title: "Can't sign in?",
            articleId: 'cant-sign-in',
          },
          {
            title: 'Expired verification?',
            articleId: 'expired-verification',
          },
        ],
      },
      {
        title: 'Making changes',
        articles: [
          {
            title: 'Change your account details',
            articleId: 'change-account-details',
          },
          {
            title: 'Delete account',
            articleId: 'delete-account',
          },
        ],
      },
    ],
  },
  administrator: {
    title: 'Administrator',
    topics: [
      {
        title: 'Managing accounts',
        articles: [
          {
            title: 'Email verification vs. code verification',
            articleId: 'email-code-verification',
          },
          {
            title: 'Getting consent',
            articleId: 'getting-consent',
          },
          {
            title: 'Renewing verifications',
            articleId: 'renewing-verifications',
          },
          {
            title: 'Adding/removing verifications',
            articleId: 'adding-removing-verifications',
          },
        ],
      },
      {
        title: 'Data and privacy',
        articles: [
          {
            title: 'What data we collect',
            articleId: 'what-data-we-collect',
          },
          {
            title: 'Request data and deletions',
            articleId: 'request-data-and-deletions',
          },
          {
            title: 'Request a usage report',
            articleId: 'request-usage-report',
          },
        ],
      },
    ],
  },
};

export default categories;
