import styled from 'styled-components';
import htmr from 'htmr';
import { Link } from 'react-router-dom';

export const Container = styled.div``;

export const Paragraph = styled.p`
  color: var(--rsSecondary);
  margin-top: 0;
`;

export const Major = styled.span`
  font-weight: bold;
  color: var(--rsMajor);
`;

export const Subheader = styled.h2`
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Image = styled.img`
  max-width: 80%;
  max-height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
  &:hover {
    color: var(--rsSecondary);
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
`;

const VideoFrame = styled.div`
  width: 648.16px;
  height: 392.99px;
  max-width: 90%;
  background: grey;
  @media screen and (max-width: 1024px) {
    width: 600px;
    height: 320px;
  }
  @media screen and (max-width: 576px) {
    width: 400px;
    height: 250px;
  }
  @media screen and (max-width: 328px) {
    width: 280px;
    height: 180px;
  }
`;

export function VideoEmbed(props) {
  return (
    <VideoWrapper>
      <VideoFrame>
        <iframe
          height='100%'
          width='100%'
          src={props.src}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='video'
        ></iframe>
      </VideoFrame>
    </VideoWrapper>
  );
}

export function CenteredImage(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Image src={props.src} />
    </div>
  );
}

export function LinkInternal(props) {
  return <StyledLink to={props.to}>{props.children}</StyledLink>;
}

const transform = {
  p: Paragraph,
  h2: Subheader,
  img: CenteredImage,
  div: Container,
  strong: Major,
  a: LinkInternal,
};

/**
 * Returns the JSX for an html string.
 * @param {string} str the bare HTML string.
 */
export default function TransformedHTMLComponent(str) {
  return htmr(str, { transform });
}
