import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RsButton from '../RsButton';

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 2.5% 10px 2.5%;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 150px;
  @media screen and (max-width: 576px) {
    width: 100px;
  }
`;

const NavTitle = styled.span`
  font-size: var(--fontH2);
  font-weight: bold;
  margin-left: 10px;
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const MainLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

export default function TopNav(props) {
  return (
    <MainWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to='/home'>
          <Logo src='/images/global/rapstudyblue.png' />
        </Link>
        <MainLink to='/home'>
          <NavTitle>Help Center</NavTitle>
        </MainLink>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a href='https://rapstudy.com/'>
          <RsButton>Back to rapStudy</RsButton>
        </a>
      </div>
    </MainWrapper>
  );
}
