import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SquareWrap = styled.div`
  margin-bottom: 20px;
  background: ${(props) => props.color};
  width: 250px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`;

const AnimationAsset = styled.img``;

const Title = styled.h1`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

const StyledLink = styled(Link)`
  color: var(--rsPrimary);
  text-decoration: none;
`;

export default function HomeSquare(props) {
  return (
    <StyledLink to={props.to}>
      <SquareWrap color={props.color}>
        <AnimationAsset src={props.src} />
        <Title>{props.title}</Title>
      </SquareWrap>
    </StyledLink>
  );
}

HomeSquare.propTypes = {
  color: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
