import React, { Component } from 'react';
import styled from 'styled-components';

const Section = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  padding-left: 5%;
  padding-right: 5%;
  background: var(--rsSurface);
  margin-top: 30px;
`;

const FooterPhoto = styled.img`
  height: 80px;
  width: auto;
  margin-right: 62.78%;
  margin-top: 40px;
  margin-bottom: 17px;
  margin-left: -3px;
  @media screen and (max-width: 576px) {
    margin-top: 25px;
  }
`;

const SocialMediaLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Line = styled.div`
  box-sizing: border-box;
  border: 0.5px solid rgba(255, 255, 255, 0.78);
`;

const Info = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RowInfo = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 60px;
`;

const Bottom = styled.div`
  box-sizing: border-box;
  margin-top: 40px;
  padding-bottom: 90px;
`;

const TOSLink = styled.a`
  text-decoration: none;
  color: var(--rsSecondary);
  &:hover {
    color: var(--rsPrimary);
    text-decoration: underline;
  }
  margin-left: 20px;
`;

const Left = styled.div`
  width: 100%;
  @media screen and (min-width: 576px) {
    width: 50%;
  }
`;

const Right = styled.div`
  width: 100%;
  @media screen and (min-width: 576px) {
    width: 50%;
    padding-left: 30px;
  }
  box-sizing: border-box;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Section>
        <div>
          <FooterPhoto src={'/images/global/rapstudyblue.png'} alt='RapStudy' />
        </div>
        <RowInfo>
          <Info>
            <Left>
              <h1 className='bold'>Couldn't find what you were looking for?</h1>
              <p>
                Let us know at{' '}
                <span className='major'>support@rapstudy.com</span> and we’ll
                get back to you as soon as possible!
              </p>
            </Left>

            <Right>
              <p>
                <b>Follow Us</b>
              </p>
              <p>
                <a
                  href='https://www.instagram.com/rapstudy/'
                  style={{
                    color: 'var(--rsSecondary)',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SocialMediaLogo
                    src={'/images/global/instagram.svg'}
                    alt='https://www.instagram.com/rapstudy/'
                  />
                  Instagram
                </a>
              </p>
              <p>
                <a
                  href='https://www.linkedin.com/company/rapstudy/'
                  style={{
                    color: 'var(--rsSecondary)',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SocialMediaLogo src={'/images/global/linkedin.svg'} alt='' />
                  LinkedIn
                </a>
              </p>
              <p>
                <a
                  href='https://www.facebook.com/RapStudy-110562207266080/'
                  style={{
                    color: 'var(--rsSecondary)',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SocialMediaLogo src={'/images/global/facebook.svg'} alt='' />
                  Facebook
                </a>
              </p>
            </Right>
          </Info>
        </RowInfo>
        <Line />

        <Bottom>
          <Info>
            <p style={{ margin: 0 }}>© 2021 rapStudy Inc.</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20PP.pdf?alt=media&token=ac72e73b-1f95-4b69-a46b-de02d0bea869'
                }
                download='rapStudy Privacy Policy'
                target='_blank'
              >
                Privacy Policy
              </TOSLink>
              <TOSLink
                secondary={true}
                href={
                  'https://firebasestorage.googleapis.com/v0/b/rapstudy-2615f.appspot.com/o/documents%2F%5BrapStudy%5D%20TOU.pdf?alt=media&token=ff00b065-3e73-43c3-8e02-0cf2b338596f'
                }
                download='rapStudy Terms of Use'
                target='_blank'
              >
                Terms of Use
              </TOSLink>
            </div>
          </Info>
        </Bottom>
      </Section>
    );
  }
}

export default Footer;
