import React from 'react';
import styled from 'styled-components';
import RsLink from '../RsLink';

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 400px;
  padding: 30px;
  background: var(--rsSurface);
  border-radius: 25px;
  margin-right: 40px;
  margin-bottom: 40px;
`;

const LinkWrapper = styled.div``;

const Header = styled.h2`
  margin: 0;
`;

function Topic(props) {
  const topic = props.topic;
  return (
    <MainWrapper>
      <Header className='bold'>{topic.title}</Header>
      <LinkWrapper>
        {topic.articles.map((ele) => (
          <div
            key={ele.articleId}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <RsLink to={`/article/${ele.articleId}`} secondary={true}>
              {ele.title}
            </RsLink>
          </div>
        ))}
      </LinkWrapper>
    </MainWrapper>
  );
}

export default Topic;
