import TopNav from './UI-Library/TopNav';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Home from './Screens/Home';
import Category from './Screens/Category';
import Article from './Screens/Article';
import Footer from './UI-Library/Footer';

function App() {
  return (
    <div className='App'>
      <TopNav />
      <Switch>
        <Route exact path='/' render = {(props) => <Redirect to='/home' /> }>
        </Route>
        <Route exact path='/home' render = {(props) => <Home />}>
        </Route>
        <Route path='/category/:categoryId' render={(props) => <Category />}>
        </Route>
        <Route path='/article/:articleId' render={(props) => <Article />}>
        </Route>
        <Route path = "*">
          <Redirect to='/home' /> :
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default withRouter(App);
