import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import HomeSquare from '../../UI-Library/HomeSquare';

const MainHeader = styled.h1`
  font-weight: 900;
  margin: 0 0 50px 0;
`;

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5%;
`;

const MainCard = styled.div`
  box-sizing: border-box;
  background: var(--rsSurface);
  width: 100%;
  max-width: 1024px;
  border-radius: 25px;
  padding: 5%;
`;

const Set = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

function Home(props) {
  return (
    <MainWrapper>
      <MainCard>
        <MainHeader>What can we help you with?</MainHeader>
        <Set>
          <HomeSquare
            to={'/category/product'}
            src='/images/global/Jake_And_Cosi.png'
            color='#F06449'
            title='Product'
          />
          <HomeSquare
            to={'/category/account'}
            src='/images/global/HallwayGirl.png'
            color='#008169'
            title='Account'
          />
          <HomeSquare
            to={'/category/administrator'}
            src='/images/global/Grant.png'
            color='#8C2155'
            title='Administrator'
          />
        </Set>
      </MainCard>
    </MainWrapper>
  );
}

export default withRouter(Home);
