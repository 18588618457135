import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import TransformedHTMLComponent, {
  VideoEmbed,
} from '../../UI-Library/ArticleElements';
import articles from '../../data/articles';
import BackArrow from '../../UI-Library/BackArrow';

const MainHeader = styled.h1`
  font-weight: 900;
  margin: 0;
  margin-left: 20px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 2.5% 5% 2.5% 5%;
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  max-width: 1024px;
  position: relative;
`;

function Article(props) {
  const articleId = props.match.params.articleId;
  const articleData = articles[articleId];

  return (
    <MainWrapper>
      <Body>
        <Header>
          <BackArrow />

          <MainHeader>
            <span className='secondary bold'>{`${articleData.categoryName} / ${articleData.topicName} / `}</span>
            {articleData.title}
          </MainHeader>
        </Header>

        {articleData.videoSrc && <VideoEmbed src={articleData.videoSrc} />}
        {TransformedHTMLComponent(articleData.html)}
      </Body>
    </MainWrapper>
  );
}

export default withRouter(Article);
