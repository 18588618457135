const articles = {
  'what-is-rapstudy': {
    title: 'What is rapStudy?',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Getting started',
    html:
      "<p> rapStudy is a for students, by students startup that helps kids learn through their favorite music. Our platform integrates educational content into the songs they know and love, so it’s as if Ariana Grande and Taylor Swift are teaching them about math, science, and social studies.</p><h2>Who can use rapStudy?</h2><p> Currently, rapStudy is operating a closed pilot program, for which we’ve partnered with select schools in NY, NJ, and CT. If you're interested in having your school learn lyrically through rapStudy, email us at <strong> info@rapstudy.com</strong>.</p>",
  },
  'browser-support': {
    title: 'Browser support',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Getting started',
    html: `<p>
    rapStudy makes use of web technologies and some third party libraries that
    require modern browsers. To avoid compatibility issues, please use rapStudy
    with either Chrome or Firefox. Incognito/Private browsing modes can also lead
    to authentication issues, so please also avoid running rapStudy in those
    modes.
  </p>
  `,
  },
  'explore-our-content-offering': {
    title: 'Explore our content offering',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Getting started',
    html: `<h2>Browse</h2>
    <p>
      rapStudy has over 250 songs on our platform, covering all subjects and grade
      levels Pre K-8. To find a song, use the Browse feature and search by topic
      (“scientific method”) or your favorite original artist / song (“Taylor Swift”
      / ”Shake It Off”)!
    </p>
    <h2>Unit view</h2>
    <p>
    Once you've clicked on a unit, you can explore the various topics within it.
    Each resulting topic can include songs that cover material from different
    standards and grades. To narrow your selection, use the filter at the top of the
    page. If this is a unit that you'll be visiting frequently, click the “Add to
    Library” button in the top right, and it will show up as a shortcut in your
    vertical navigation.</p>
  `,
  },
  karaoke: {
    title: 'Karaoke',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Our features',
    html: `<p>
    Karaoke lets you listen to the song with our word tracker. It's a great way to
    follow along and learn the lyrics! Many of our songs also have variations
    based on other pop hits. If they're available, check them out by clicking on
    the dropdown menu in the top right corner.
  </p>
  <p>
    If you like the song, click on the "Add to Playlist" button, and choose one of
    your playlists or create a new one. To share the song with your peers /
    students, copy the link to your clipboard, or take advantage of our Google
    Classroom sharing integration to quickly post a link.
  </p>
  `,
    videoSrc: 'https://www.youtube.com/embed/wQpHMZn0DGo',
  },
  breakdown: {
    title: 'Breakdown',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Our features',
    html: `<p>
    Breakdown lets you annotate the song by adding notes and pictures to the
    lyrics.
  </p>
  <p>
    To create a new annotation, first click on the pencil icon to enter Edit Mode.
    Click and drag to highlight a set of lyrics, then click on “New Annotation.”
    In the pop-up, you can type in your notes, and optionally add an image. Use
    the built in search feature to quickly find an image, or upload your own.
  </p>
  <p>
    Click the title if you need to edit it, and once you’re satisfied with your
    changes, make sure to click “Save.”
  </p>
  <p>To enlarge the text, click the fullscreen icon to expand the window.</p>
  <p>
    To share the study set with your peers / students, take advantage of our
    Google Classroom sharing integration, or copy the link by clicking on the copy
    icon.
  </p>
  `,
    videoSrc: 'https://www.youtube.com/embed/r0i1CVC8rPg',
  },
  blanks: {
    title: 'Blanks',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Our features',
    html: `<p>
    Blanks helps engage free recall of keywords. When the song reaches a keyword,
    it will pause. Clicking play again will continue the song and show the word.
  </p>
  <p>
    To edit your keywords, first click on the pencil icon to enter Edit Mode.
    Click on any word to make it a keyword. To toggle it back, simply click on the
    highlighted word again. Click the title if you need to edit it, and once
    you’re satisfied with your changes, make sure to click “Save.”
  </p>
  <p>To enlarge the text, click the fullscreen icon to expand the window.</p>
  <p>
    To share the study set with your peers / students, take advantage of our
    Google Classroom sharing integration, or copy the link by clicking on the copy
    icon.
  </p>
  `,
    videoSrc: 'https://www.youtube.com/embed/jtnpto51tV4',
  },
  review: {
    title: 'Review',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Our features',
    html: `<p>
    Review allows you to create multiple choice questions based on the song. Each
    question can be linked to a selection of the song’s lyrics, which will play
    after clicking the “Show Hint” button.
  </p>
  <p>
    To edit your questions, first click on the pencil icon to enter Edit Mode.
    Click the button at the bottom of the page to add a question. Then enter your
    question and answer choices, and click on a bubble to mark the correct answer.
  </p>
  <p>
    To add lyrics to the question, click the “Choose Lyrics” button. In the window
    that appears, highlight the lyrics you want to use with your cursor, then
    click “Use Selection.” Once you are happy with your selection, click “Save.”
  </p>
  <p>
    To add an image, click the “Choose Image” button. Use the built in search
    feature to quickly find an image, or upload your own.
  </p>
  <p>
    To reorder your questions, simply drag and drop the cards by clicking and
    holding the drag handle icon. Click the title if you need to edit it, and once
    you’re satisfied with your changes, make sure to click “Save.”
  </p>
  <p>To enlarge the text, click the fullscreen icon to expand the window.</p>
  <p>
    To share the study set with your peers / students, take advantage of our
    Google Classroom sharing integration, or copy the link by clicking on the
    "copy" icon.
  </p>
  `,
    videoSrc: 'https://www.youtube.com/embed/A5oQMT4KpYE',
  },
  'your-study-sets': {
    title: 'Your study sets',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Organizing and sharing',
    html: `<p>
    Study Sets are the content generated by you for Breakdown, Blanks, and Review.
  </p>
  <p>
    To quickly get an overview of the content you've created, click on the Your
    Study Sets link in the vertical navigation bar. There, your study sets are
    organized by their respective songs.
  </p>
  <p>
    To see all sets in a song, click the song card. From there, you can create /
    remove study sets -- and if you're a teacher, you can share them to your class
    by clicking on the dropdown next to the name of the study set.
  </p>
  
  `,
  },
  playlists: {
    title: 'Playlists',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Organizing and sharing',
    html: `<p>
    Playlists are a collection of songs. Group together songs you like so you can
    quickly access them in the navigation bar!
  </p>
  <h2>Add/organize songs</h2>
  <p>
    You can add songs from Song View by clicking on the dropdown at the top right.
    If you're in a playlist (whether it's yours or someone else's), click the
    dropdown icon next to a song to add it to one of your playlists.
  </p>
  <p>
    To rearrange the order of songs, click and drag the drag handle icon to the
    right. Your changes will be automatically saved. To remove a song from the
    playlist, click the dropdown icon and click "Remove from playlist.”
  </p>
  <h2>Sharing playlists</h2>
  <p>
    To share your playlist, you can copy the link by clicking on the copy icon, or
    take advantage of our Google Classroom sharing integration.
  </p>  
  `,
  },
  'sharing-to-google-classroom': {
    title: 'Sharing to Google Classroom',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Organizing and sharing',
    html: `<p>
    We support Google Classroom sharing from within rapStudy for multiple types of
    content.
  </p>
  <h2>Songs and Study Sets:</h2>
  <p>
    When you're in Song View, clicking the Google Classroom icon in the top right
    will open a pop-up. Select the class you'd like to share to, and change the
    details of the post as needed. If you're using Breakdown, Blanks, or Review,
    the link getting shared by the pop-up will dynamically change.
  </p>
  <h2>Playlists</h2>
  <p>
    Sharing a playlist works the same way as sharing a song. Click the icon will
    cause a pop-up to appear. Edit the post details as needed.
  </p>
  <h2>Class PIN Announcement</h2>
  <p>
    rapStudy uses unique PIN codes for each class to handle enrolling students. If
    you're a teacher, click the Google Classroom icon in the banner to share the
    PIN to your students.
  </p>
  <h2>Assignments</h2>
  <p>
    Teachers can share rapStudy assignments to Google Classroom as well. This
    option defaults to the "Assignment" type of post, but feel free to change it
    as needed. At this time, rapStudy does not support automatic grade uploading
    to Google Classroom gradebooks. (But we will soon!)
  </p>
  
  `,
  },
  'creating-a-class': {
    title: 'Creating a class',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (teachers)',
    html: `<p>
    Classes allow teachers to share content with their students and to create
    assignments.
  </p>
  <h2>Creating a class</h2>
  <p>
    To create a class, first click on <strong>Your Classes</strong> in the
    vertical navigation. Then click on the "Create Class" button in the top right.
    Add a title and an optional description, and click “Submit.”
  </p>
  <h2>Sharing the PIN</h2>
  <p>
    Students join your class by using the unique code shown in the top right. To
    make it easier for students to join, click the button with the PIN listed for
    an expanded pop-up. Alternatively, click the icon to copy the PIN to your
    clipboard. If you're using Google Classroom, you can also make a post to
    Classroom from rapStudy announcing the PIN.
  </p>
  <h2>Adding and removing students</h2>
  <p>
    When students have made a request to join, their name will appear in the
    <strong>Pending</strong> section under the <strong>Members</strong> page.
    There you'll be able to accept or reject the student's requests to join. (Note
    that as of right now, the accept operations may take a few seconds, so don't
    worry if it takes a second to go through.)
  </p>
  <p>
    To remove a student from a class, simply click "Remove" next to their name in
    the list of students in your class.
  </p>
  
  `,
  },
  'sharing-content': {
    title: 'Sharing content',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (teachers)',
    html: `<p>
    As the teacher, you can share songs, study sets, and playlists with your
    students.
  </p>
  <h2>Songs</h2>
  <p>
    Songs can be shared by clicking on the dropdown in the <strong>Song View</strong> banner, or in
    <strong>Playlists</strong> by clicking on the dropdown in a song row.
  </p>
  <h2>Study Sets</h2>
  <p>
    Study sets can be shared to a class in the Song View banner, or on the Your
    Study Sets page.
  </p>
  <h2>Playlists</h2>
  <p>
    Playlists can be shared to a class by clicking on the dropdown icon in the top
    right of the banner.
  </p>
  
  `,
  },
  'creating-an-assignment': {
    title: 'Creating an assignment',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (teachers)',
    html: `<h2>What are assignments?</h2>
    <p>
      Assignments allow you to share multiple <strong>Review</strong> study sets
      with your students, and view their results and progress. (We'll be launching
      assignments for our other features soon!)
    </p>
    <h2>
      How is this different from sharing a study set in the "Shared Content"
      section?
    </h2>
    <p>
      How is this different from sharing a study set in the "Shared Content"
      section? When you assign a Review study set as an assignment, you can select
      the release and due dates. Students will not be able to complete the
      assignment until it is released. With sets shared in "Shared Content,” there
      is no time limit, submission viewing, or difficulty selections.
    </p>
    <h2>Step 1: Make your Review study sets</h2>
    <p>
      Assignments are built on top of Review! It's a great way to test your
      students' knowledge while linking everything back to the music. For more
      information about Review and how to make questions, click
      <a to="/article/review">here</a>. Make sure that you've finalized your study
      sets before you make the assignments; you can’t change the questions after
      you’ve created the assignment!
    </p>
    <h2>Step 2: Set up the details</h2>
    <p>
      When you have your study sets ready, go to your Class and click the
      <strong>Assignments</strong> tab, then click the "Create Assignment" button.
      Fill out the assignment name and an optional description. Then, select a
      release date and a due date by using the built in calendar date-picker. When
      you're ready, click “Next.” (You can edit these details after the assignment
      has been created.)
    </p>
    <h2>Step 3: Select your sets</h2>
    <p>
      On the next screen, you'll see the song cards for the (non-empty) Review study
      sets you've made. Select a study set by clicking on the song card to expand
      the card, then click on the checkbox next to your desired study set. When a
      set is active, a small card appears above with its details. To deselect a set,
      simply click the checkbox again.
    </p>
    <h2>Step 4: Reorder and select modes</h2>
    <p>
      To change the order of study sets, click and drag on the drag handle icon in
      the bottom right of the card. You'll also see a dropdown menu in each card.
      From that menu, choose a difficulty that matches the purpose of this
      assignment. We offer three modes for Review:
    </p>
    <p><strong>Easy</strong>: Unlimited attempts + lyric hint always available.</p>
    <p>
      <strong>Medium</strong>: 2 attempts + lyric hint available after the first
      attempt.
    </p>
    <p>
      <strong>Hard</strong>: 1 attempt + lyric hint only visible after completing
      the question.
    </p>
    <h2>Step 5: Finish and share!</h2>
    <p>
      Once you're satisfied with your assignment, finish by clicking the "Submit"
      button. Once it's created, you and your students will be able to access it
      from the <strong>Assignments</strong> tab in your Class page. If you use
      Google Classroom, use our Google Classroom sharing integration to make a post
      to Classroom directly from our app by clicking into the assignment, then
      clicking the Google Classroom icon.
    </p>
    
  `,
  },
  'viewing-student-submissions': {
    title: 'Viewing student submissions',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (teachers)',
    html: `<p> Once your assignment has been released and your students have made progress on the study sets, you can view their results by first going to the <strong>Assignments</strong> tab in the class navigation, then clicking on an assignment.</p><p> On the <strong>Summary</strong> page, you'll see an overview of everyone's scores. To see the specifics of individual responses, click on a student's name in the table, or click on the <strong>Individual</strong> tab in the banner.</p><p> To see what your students are seeing when they complete the assignment, click the <strong>Preview</strong> tab to take a mock version of your assignment (note that no progress will be saved).</p>
  `,
  },
  'joining-a-class': {
    title: 'Joining a class',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (students)',
    html: `<p>
    Once your teacher has made a class, they should share its unique PIN code with
    you. This is a seven-digit combination made of letters and numbers.
  </p>
  <p>
    To join the class, first go to the <strong>Your Classes</strong> page from the
    vertical navigation. Click the "Join Class" button in the top right, and enter
    your code.
  </p>
  <p>
    If your code is valid, your teacher will receive the request, and you will
    have to wait for them to accept your request. When your request has been
    accepted, you will be able to see the class appear in your list of classes.
  </p>
  
  `,
  },
  'doing-an-assignment': {
    title: 'Doing an assignment',
    categoryId: 'product',
    categoryName: 'Product',
    topicName: 'Classes (students)',
    html: `<p>
    When assignments are released, a blue bubble will appear next to the “Your
    Classes” link in the vertical navigation bar. This is the number of
    outstanding assignments you have to complete.
  </p>
  <p>
    On the <strong>Your Classes</strong> page, you can see how many assignments
    are outstanding for each class. Click on a class, then click on the
    <strong>Assignments</strong> tab to view them.
  </p>
  <p>
    Start an assignment by clicking on the assignment card. Note that your teacher
    can assign multiple study sets for each assignment. Your responses will be
    saved automatically, so you can always leave the page and come back to your
    work. Once you have completed the assignment, your grades will automatically
    be sent to your teacher.
  </p>`,
  },
  'create-account-google': {
    title: 'Creating an account via Google',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Logging in',
    html: `<p> Creating an account via Google is the simplest and quickest way to get started! Simply click “Register” in the top right corner of our landing page. You can then choose to “Continue with Google,” and you’ll be directed to a new page where you can log in with your Google account. Make sure to use the account with which you have been verified, unless you have a verification code that has already been given to you. </p>
`,
  },
  'create-account-email': {
    title: 'Creating an account via Email and Password',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Logging in',
    html: `<p> If you are using a non-Google email address, choose “Continue with Email” after clicking “Register” in the top right corner of our landing page. You can then enter your email address and choose a password. Make sure to use the account with which you have been verified, unless you have a verification code that has already been given to you. You will then be sent a verification email to the inputted email address. Click on the link in that email to confirm your identity and continue the sign-up process. </p>
`,
  },
  'onboarding-and-verification': {
    title: 'Onboarding and verification',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Logging in',
    html: `<p>
    Once you have signed in with your email (and verified it, if necessary), one
    of two things may happen:
  </p>
  <p>
    1. If your email has been previously verified by an administrator, you should
    be brought straight to the onboarding screens, starting with a prompt for your
    name.
  </p>
  <p>
    2. If your email has NOT been previously verified by an administrator, but you
    have a verification code provided to you, you will be prompted to enter the
    code to complete the creation of your account. You will then be brought to the
    onboarding screens, starting with a prompt for your name.
  </p>
  <p>
    If your email is not verified and you do not have a code, please contact your
    administrator.
  </p>
  
`,
  },
  'forgot-password': {
    title: 'Forgot your password?',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Troubleshooting',
    html: `<p> If you’ve forgotten your password, click go to the “Sign In” screen, and click the “Forgot Password” button. Enter the email address associated with your account, and you will receive an automated email in that inbox (it may appear in your Spam). Follow the instructions in that email to reset your password.</p>
`,
  },
  'cant-sign-in': {
    title: "Can't sign in?",
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Troubleshooting',
    html: `<p>
    First, make sure you’re using the right email address associated with your
    account. If you’re using rapStudy through your school, it should be your
    organization’s email. Also ensure that you are using one of our supported
    browsers (Chrome or Firefox) and that you are not in Private browsing mode. If
    you still run into issues, email <strong>support@rapstudy.com</strong> and
    we’ll help as soon as possible!
  </p>
  
`,
  },
  'expired-verification': {
    title: 'Expired verification?',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Troubleshooting',
    html: `<p>
    If your verification has expired, please contact your administrator. If you
    believe this to be a mistake, email <strong>support@rapstudy.com</strong>.
  </p>
  
`,
  },
  'change-account-details': {
    title: 'Change your account details',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Making changes',
    html: `<p>
    If you need to change your name or other basic profile information, visit the
    <strong>Settings</strong> page in the vertical navigation bar. Click the
    “Edit” button in the top right, then save your changes.
  </p>
  <p>
    You cannot change your email address associated with the account in our web
    app. However, if you wish to do so, email
    <strong>support@rapstudy.com</strong>.
  </p>
  
`,
  },
  'delete-account': {
    title: 'Delete account',
    categoryId: 'account',
    categoryName: 'Account',
    topicName: 'Making changes',
    html: `<p>
    Users cannot delete their account from within our app. If you wish to do so,
    email <strong>support@rapstudy.com</strong>. As per our Privacy Policy, we
    will remove all personal information when your account is deleted.
  </p>
  
`,
  },
  'email-code-verification': {
    title: 'Email verification vs. code verification',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    Since rapStudy is currently only open to partnered schools, we require an
    extra step of verification to confirm users’ access. There are three main ways
    of doing this:
  </p>
  <h2>Email verification (recommended):</h2>
  <p>
    All we need from you is a list of emails, separated into teachers and
    students. This can be in .csv format. We’ll handle everything else on our
    backend, and your users will be able to simply Register / Sign In with those
    emails.
  </p>
  <h2>Generated code verification</h2>
  <p>
    If you are unable to get emails of your users, we can generate unique codes
    for you to distribute to your teachers or students. Note that these codes are
    permissions-based and one-time use, so only use teacher codes for teachers,
    and student codes for students. When the user signs in for the first time,
    they will have to enter the code to complete the verification.
  </p>
  <h2>Custom code verification</h2>
  <p>
    If you are unable to get emails of your users but have another means of
    identification such as lunch codes, we can use those codes for verification as
    well. Similar to the above method, the user will have to enter the code during
    the onboarding process to complete the verification.
  </p>
  
`,
  },
  'getting-consent': {
    title: 'Getting consent',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    The verification process for your teachers is simple: Just send us their
    emails or how many codes you would like, and we’ll complete the verifications
    for you. However, in accordance with our privacy policy and U.S. privacy
    regulation, we require parental / school consent before we can proceed with
    verifying student accounts (for students under 13).
  </p>
  <p>
    To do this, we’ll send you a consent form to be completed, and when that has
    been signed, we’ll verify your students.
  </p>
  
`,
  },
  'renewing-verifications': {
    title: 'Renewing verifications',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    All verifications have an expiration date (with the exact date depending on
    the partnership). Once verifications have expired, your users will no longer
    have access to the rapStudy platform.
  </p>
  <p>
    To renew access, email us with an updated list of users. For users who are not
    present on the new list, we will not renew access, and for new users, we will
    create new verifications.
  </p>
  <p>
    Note that when verifications expire, users’ content (playlists, assignments,
    classes) are not deleted; if their access is renewed, their account will be
    just as they left it.
  </p>
  
`,
  },
  'adding-removing-verifications': {
    title: 'Adding/removing verifications',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    At any point, we can add or revoke access to users in your organization. Just
    email <strong>support@rapstudy.com</strong>, and we’ll make the changes you
    need as soon as possible.
  </p>
  
`,
  },
  'what-data-we-collect': {
    title: 'What data we collect',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Data and privacy',
    html: `<p>
    While the exact details of our Privacy Policy can be found in the link in the
    website footer, some key points include:
  </p>
  <p>
    For each user, we collect their name, email address, grade level, and subjects
    studied. We will never share your email address with anyone, inside or outside
    of the rapStudy application. Note that for users under 13, we require parental
    consent. See the article
    <a to="/article/getting-consent">“Getting parental consent”</a> for more
    information.
  </p>
  <p>
    We will never sell your personal information. However, by using our app, you
    agree to let us use such information internally to make better features and
    recommendations for you. You also agree to let us use anonymized, aggregated
    data externally for marketing / school reporting purposes.
  </p>
  
  
`,
  },
  'request-data-and-deletions': {
    title: 'Request data and deletions',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    As per our Privacy Policy, you can email
    <strong>support@rapstudy.com</strong> to request details about what personal
    information we are collecting, or request the deletion of your users’ personal
    data. Note that in doing so, we will delete account information and all
    user-generated content.
  </p>
  
`,
  },
  'request-usage-report': {
    title: 'Request a usage report',
    categoryId: 'administrator',
    categoryName: 'Administrator',
    topicName: 'Managing accounts',
    html: `<p>
    We know that data helps administrators make better decisions. If you would
    like to view the details of your students’ and teachers’ usage, please email
    <strong>support@rapstudy.com</strong>, and we’ll get back to you as soon as
    possible with a personalized report based on your order details.
  </p>
  
`,
  },
};
export default articles;
