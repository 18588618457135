import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Caret extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  handleEnter() {
    this.setState({ hover: true });
  }

  handleLeave() {
    this.setState({ hover: false });
  }

  renderPath(color) {
    if (this.props.direction === 'up') {
      return (
        <svg
          width='24'
          height='17'
          viewBox='0 0 24 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M21.1289 14.1289L11.707 2.26172'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
          <path
            d='M11.8496 2.25659L2.74401 14.0868'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
        </svg>
      );
    }
    if (this.props.direction === 'down') {
      return (
        <svg
          width='24'
          height='17'
          viewBox='0 0 24 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M2.74316 2.25658L12.1651 14.1238'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
          <path
            d='M12.0225 14.1289L21.1281 2.29869'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
        </svg>
      );
    }
    if (this.props.direction === 'right') {
      return (
        <svg
          width='17'
          height='24'
          viewBox='0 0 17 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          transform='rotate(180)'
        >
          <line
            x1='14.6772'
            y1='2.80995'
            x2='2.81'
            y2='12.2319'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
          <line
            x1='2.80478'
            y1='12.0896'
            x2='14.635'
            y2='21.1952'
            stroke={color}
            strokeWidth='4'
            strokeLinecap='round'
          />
        </svg>
      );
    }
    return (
      // left
      <svg
        width='17'
        height='24'
        viewBox='0 0 17 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line
          x1='14.6772'
          y1='2.80995'
          x2='2.81'
          y2='12.2319'
          stroke={color}
          strokeWidth='4'
          strokeLinecap='round'
        />
        <line
          x1='2.80478'
          y1='12.0896'
          x2='14.635'
          y2='21.1952'
          stroke={color}
          strokeWidth='4'
          strokeLinecap='round'
        />
      </svg>
    );
  }

  render() {
    const hover =
      typeof this.props.hover !== 'undefined'
        ? this.props.hover
        : this.state.hover;

    const color = this.props.disabled
      ? 'var(--rsDisabled)'
      : hover
      ? 'var(--rsPrimary)'
      : 'var(--rsSecondary)';
    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        onClick={this.props.disabled ? null : this.props.onClick}
        style={{
          cursor:
            this.props.onClick && !this.props.disabled ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {this.renderPath(color)}
      </div>
    );
  }
}

Caret.propTypes = {
  /**
   * Should the icon take on disabled styling?
   */
  disabled: PropTypes.bool,
  /**
   * What direction is the caret facing?
   */
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']).isRequired,
  /**
   * The function fired when the icon is clicked.
   */
  onClick: PropTypes.func,
  /**
   * A hover override from the parent component. If defined, the local
   * state hover is ignored.
   */
  hover: PropTypes.bool,
};

export default Caret;
