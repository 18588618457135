import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import Topic from '../../UI-Library/Topic';
import categories from '../../data/categories';
import BackArrow from '../../UI-Library/BackArrow';

const PageWrapper = styled.div`
  padding: 2.5% 5% 2.5% 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;
`;

const MainHeader = styled.h1`
  font-weight: 900;
  margin: 0;
  margin-left: 20px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
`;

const Set = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function Category(props) {
  const catId = props.match.params.categoryId;
  const catData = categories[catId];

  return (
    <PageWrapper>
      <MainWrapper>
        <Header>
          <BackArrow />
          <MainHeader>{catData.title}</MainHeader>
        </Header>
        <Set>
          {catData.topics.map((topic) => (
            <Topic key={topic.title} topic={topic} />
          ))}
        </Set>
      </MainWrapper>
    </PageWrapper>
  );
}

export default withRouter(Category);
